"use client"

import { Video } from "@/components/video"
import { VideoModalContext } from "@/contexts/video-modal"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useContext } from "react"

import "@vidstack/react/player/styles/default/theme.css"

import "@vidstack/react/player/styles/default/layouts/video.css"

export const VideoModal = (): JSX.Element | null => {
  const context = useContext(VideoModalContext)
  if (!context) {
    throw new Error(
      "VideoModalContext is not defined. Did you forget to wrap your component with the VideoModalProvider?"
    )
  }

  const { open, setOpen, data } = context

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-zinc-800 bg-opacity-60 backdrop-blur" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative text-left overflow-hidden mx-4 shadow-xl transform transition-all w-[100vh] md:max-w-[1000px] 2xl:max-w-[1600px]">
                <div>{data?.primaryVideo?.muxId ? <Video {...data} /> : null}</div>
                <div className="mt-4 md:mt-2 lg:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full p-2 font-sans text-sm font-bold text-black bg-yellow-300 border border-transparent rounded-md shadow-sm lg:p-4 hover:bg-yellow-400 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
