import { subDays } from "date-fns"
import { z } from "zod"

// Base Mux URLs
const muxStreamBaseUrl = "https://stream.mux.com"
const muxImageBaseUrl = "https://image.mux.com"

// Thumbnail settings
const thumbnailTimestamp = 2
const thumbnailWidth = 980

// Some basic settings for the video player
const autoplay = true
const controls = true
const loop = false
const muted = false
const playsInline = true

export const MuxVideo = z
  .object({
    id: z.string().min(1).startsWith("mux-video:"),
    muxId: z.string().min(1),
  })
  .strict()
export type MuxVideo = z.infer<typeof MuxVideo>

const uploadDate = subDays(new Date(), 7).toISOString()

// TypeScript is inferring the return type for `toMuxVideoForDisplay`. Thanks!
export function toMuxVideoForDisplay(video?: MuxVideo) {
  if (!video) {
    return undefined
  }

  const { muxId } = video

  return {
    ...video,
    uploadDate,
    srcUrl: toMuxStreamUrl(muxId),
    posterUrl: toMuxPosterUrl(muxId),
    thumbnailsStoryboardUrl: toThumbnailsStoryboardUrl(muxId),
    autoplay,
    controls,
    loop,
    muted,
    playsInline,
  }
}

function toMuxStreamUrl(muxId: string) {
  return new URL(`${muxStreamBaseUrl}/${muxId}.m3u8`).toString()
}

function toMuxPosterUrl(muxId: string) {
  const url = new URL(`${muxImageBaseUrl}/${muxId}/thumbnail.webp`)
  url.searchParams.set("time", thumbnailTimestamp.toString())
  url.searchParams.set("width", thumbnailWidth.toString())
  return url.toString()
}

function toThumbnailsStoryboardUrl(muxId: string) {
  return new URL(`${muxImageBaseUrl}/${muxId}/storyboard.vtt`).toString()
}
