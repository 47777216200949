"use client"

import { VideoModalContext } from "@/contexts/video-modal"
import type { CaseStudy } from "@/models/case-study"
import type { ReactNode } from "react"
import { useMemo, useState } from "react"

export const VideoModalProvider = ({ children }: { children: ReactNode }): JSX.Element | null => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<CaseStudy | undefined>(undefined)
  const value = useMemo(
    () => ({
      open,
      setOpen,
      data,
      setData,
    }),
    [open, setOpen, data, setData]
  )
  return <VideoModalContext.Provider value={value}>{children}</VideoModalContext.Provider>
}
