import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  server: {},
  client: {
    NEXT_PUBLIC_MUX_DATA_ENV_KEY: z.string(),
    NEXT_PUBLIC_ENVIRONMENT: z.enum(["local", "development", "production"]),
    NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN: z.string().url(),
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: z.string(),
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_MUX_DATA_ENV_KEY: process.env["NEXT_PUBLIC_MUX_DATA_ENV_KEY"],
    NEXT_PUBLIC_ENVIRONMENT: process.env["NEXT_PUBLIC_ENVIRONMENT"],
    NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN: process.env["NEXT_PUBLIC_PRIMARY_FQDN_DOMAIN"],
    NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: process.env["NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME"],
  },
})
