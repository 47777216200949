"use client"

import type { CaseStudy } from "@/models/case-study"
import type { Dispatch, SetStateAction } from "react"
import { createContext } from "react"

type VideoModalContext = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  data: CaseStudy | undefined
  setData: Dispatch<SetStateAction<CaseStudy | undefined>>
}

export const VideoModalContext = createContext<VideoModalContext>({
  open: false,
  setOpen: () => null,
  data: undefined,
  setData: () => null,
})
