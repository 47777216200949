import { monotonicFactory } from "ulid"

const ulid = monotonicFactory()

export interface DeviceIDGenerator {
  generateDeviceId(prefix: string): string
}

export function ULIDDeviceIDGenerator(): DeviceIDGenerator {
  function generateDeviceId(prefix: string): string {
    return `${prefix}${ulid()}`
  }

  return {
    generateDeviceId,
  }
}
